import { useZakeke } from '@zakeke/zakeke-configurator-react';
import { T } from '../Helpers';
import { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckSolid } from './check-circle-solid.svg';

const LoadingLabel = styled.div`
	color: #000;
	font-size: 12px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	line-height: 16px;
`;

const LoaderContainer = styled.div<{ $isMobile: boolean }>`
	height: 8px;
	${(props) => (props.$isMobile ? `width: 250px` : `width: 600px`)};
	border-radius: 4px;
	background-color: #dbe2e6;
`;

const LoadingPercentageLabel = styled.span`
	color: #8fa4ae;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	font-style: normal;
	font-family: 'Inter';
`;

const LoadingPercentageandIconContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Icon = styled.div<{ hoverable?: boolean }>`
	display: inline-block;
	width: 24px;
	height: 24px;
	cursor: pointer;

	${(props) =>
		props.hoverable &&
		`
    @media(hover) {
      &:hover {
        opacity: 0.5
      }
    }
  `}

	svg {
		fill: currentColor;
		width: 100%;
		height: 100%;
	}
`;

const CheckIcon = styled(Icon)`
	cursor: unset;
	color: #008556;
`;

const LoaderFill = styled.div<{ $percentage: number; $barColor: string; $isCompleted: boolean }>`
	height: 100%;
	border-radius: 4px;
	margin: 7px 0px;
	${(props) => props.$percentage && `width: ${props.$percentage}% `};
	${(props) =>
		props.$barColor && props.$isCompleted ? `background-color: #008556` : `background-color: ${props.$barColor}`};
	border-radius: 'inherit';
`;

const ProgressBar: FC<{ flagStartLoading: boolean; barColor: string; isCompleted: number }> = ({
	flagStartLoading,
	barColor,
	isCompleted
}) => {
	const { isSceneLoading } = useZakeke();
	
	return (
		<div>
			<LoadingLabel>
				{isSceneLoading ? T._('Loading your product...', 'Composer') : T._('Loading complete.', 'Composer')}
			</LoadingLabel>
			<LoaderContainer $isMobile={window.innerWidth < 1024}>
				<LoaderFill
					$percentage={!isSceneLoading && flagStartLoading ? 100 : isCompleted}
					$barColor={barColor}
					$isCompleted={!isSceneLoading && flagStartLoading}
				></LoaderFill>
				<LoadingPercentageandIconContainer>
					<LoadingPercentageLabel>
						{isSceneLoading ? T._('In progress | ', 'Composer') + `${isCompleted}%` : '100%'}
					</LoadingPercentageLabel>
					{!isSceneLoading && (
						<CheckIcon>
							<CheckSolid />
						</CheckIcon>
					)}
				</LoadingPercentageandIconContainer>
			</LoaderContainer>
		</div>
	);
};

export default ProgressBar;
