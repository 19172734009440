import { useZakeke, ZakekeViewer } from '@zakeke/zakeke-configurator-react';
import QRCode from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import './App.css';
// import arIcon from './ar_icon.png';
import { TailSpin } from 'react-loader-spinner';
import {
	ArCustomIcon,
	ArIcon,
	Button,
	Container,
	Dialog,
	FullscreenIcon,
	HandIcon,
	InitialLoader,
	InitialLoaderContainer,
	LoadingContainer,
	SecondScreenIcon,
	ZoomInIcon,
	ZoomOutIcon
} from './Styled';
import deviceAndroid from './device_android.png';
import deviceIOS from './device_ios.png';
import handIcon from './hand_icon.png';
import ProgressBarLoadingOverlay from './loadingComponents/ProgressBarLoadingOverlay';
import { TryOnViewer } from './tryon/TryOnViewer';
import TryOnsButton from './tryon/TryOnsButtons';

const DeviceSelectionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	height: 320px;
	padding: 30px;
`;

const DeviceSelectionsTitle = styled.span`
	font-size: 22px;
	margin-bottom: 20px;
	font-weight: bold;
`;

const DeviceSelections = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 40px;
	height: 200px;
	justify-content: center;
	align-items: center;

	img {
		background-color: #f3f5f6;
		width: 100%;
		height: 200px;
		object-fit: contain;
		padding: 30px 40px;
		border-radius: 10px;
		cursor: pointer;

		&:hover {
			box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
		}
	}
`;

const QRCodeContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 20px 40px;

	span {
		size: 20px;
		text-align: center;
		margin-top: 20px;
		&:first-of-type {
			margin-top: 20px;
		}
	}
`;

const StyledZakekeViewer = styled(ZakekeViewer)`
	canvas {
		display: block;
	}
`;

function App() {
	const {
		isSceneLoading,
		isAssetsLoading,
		zoomIn,
		zoomOut,
		IS_ANDROID,
		IS_IOS,
		product,
		openArMobile,
		getQrCodeArUrl,
		getMobileArUrl,
		isSceneArEnabled,
		setCameraLocked,
		hasVTryOnEnabled,
		getTryOnSettings,
		openSecondScreen,
		uiConfig
	} = useZakeke();
	const ref = useRef<HTMLDivElement | null>(null);
	const [dialogs, setDialogs] = useState<[id: string, dialog: React.ReactElement][]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [flagStartLoading, setFlagStartLoading] = useState(false);
	const [longDelayedLoading, setLongDelayedLoading] = useState(true);
	const queryParams = new URLSearchParams(window.location.search);
	const showZoom = queryParams.get('nozoom') !== '1';
	const showAR = queryParams.get('noar') !== '1';
	const showFullscreen = queryParams.get('nofullscreen') !== '1';

	// added a flag because at the very beginning of the loading the isSceneLoading is false
	// requested this delay for the progress bar dialog
	useEffect(() => {
		if (isSceneLoading) setFlagStartLoading(true);
		if (!isSceneLoading && flagStartLoading) setTimeout(() => setLongDelayedLoading(false), 2000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSceneLoading]);

	useEffect(() => {
		if (!isSceneLoading && product) {
			const queryParams = new URLSearchParams(window.location.href);
			const showZoom = queryParams.get('nozoom') !== '1';
			if (!showZoom) setCameraLocked(true);
		}
		// eslint-disable-next-line
	}, [isSceneLoading, product]);

	const switchFullscreen = () => {
		if (
			(document as any).fullscreenElement ||
			(document as any).webkitFullscreenElement ||
			(document as any).mozFullScreenElement ||
			(document as any).msFullscreenElement
		) {
			quitFullscreen(ref.current!);
		} else {
			launchFullscreen(ref.current!);
		}
	};

	const quitFullscreen = (element: HTMLElement) => {
		let exitFullscreen =
			document.exitFullscreen || (document as any).webkitExitFullscreen || (document as any).msExitFullscreen || (document as any).mozCancelFullScreen;
		if (exitFullscreen) exitFullscreen.call(document as any);
	};

	const launchFullscreen = (element: HTMLElement) => {
		let requestFullScreen =
			element.requestFullscreen ||
			(element as any).webkitRequestFullscreen ||
			(element as any).msRequestFullscreen ||
			(element as any).mozRequestFullScreen;

		if (requestFullScreen) requestFullScreen.call(element);
	};

	const handleArClick = async (arOnFlyUrl: string) => {
		if (IS_ANDROID || IS_IOS) {
			setIsLoading(true);
			const link = new URL(arOnFlyUrl, window.location.href);
			const url = await getMobileArUrl(link.href);
			setIsLoading(false);

			if (url)
				if (IS_IOS) {
					openArMobile(url as string);
				} else if (IS_ANDROID) {
					showDialog(
						'open-ar',
						<Dialog onCloseClick={() => closeDialog('open-ar')}>
							<Button
								style={{ display: 'block', width: '100%' }}
								onClick={() => {
									closeDialog('open-ar');
									openArMobile(url as string);
								}}
							>
								VIEW PRODUCT IN AR
							</Button>
						</Dialog>
					);
				}
		} else {
			showDialog(
				'select-ar',
				<Dialog onCloseClick={() => closeDialog('select-ar')}>
					<DeviceSelectionsContainer>
						<DeviceSelectionsTitle>Choose your device</DeviceSelectionsTitle>
						<DeviceSelections>
							<img
								src={deviceAndroid}
								alt='android'
								onClick={() => {
									closeDialog('select-ar');
									showQrCode('Android');
								}}
							/>
							<img
								src={deviceIOS}
								alt='ios'
								onClick={() => {
									closeDialog('select-ar');
									showQrCode('iOS');
								}}
							/>
						</DeviceSelections>
					</DeviceSelectionsContainer>
				</Dialog>
			);
		}
	};

	const showQrCode = async (type: 'iOS' | 'Android') => {
		setIsLoading(true);
		const url = await getQrCodeArUrl(type);
		setIsLoading(false);

		showDialog(
			'qr',
			<Dialog onCloseClick={() => closeDialog('qr')}>
				<QRCodeContainer>
					<h2>Scan the QR code with your phone</h2>
					<QRCode value={url} size={200} />
					<span> iOS 15+, iPadOS 15+ </span>
					<span>or Android with ARCore 1.9+ required </span>
				</QRCodeContainer>
			</Dialog>
		);
	};

	const showDialog = (id: string, dialog: React.ReactElement) => {
		setDialogs((prev) => [...prev, [id, dialog]]);
	};

	const closeDialog = (id: string) => {
		setDialogs((prev) => prev.filter((x) => x[0] !== id));
	};
	
	return (
		<Container ref={ref}>
			{!longDelayedLoading && !isSceneLoading && <HandIcon src={handIcon} />}
			{!isSceneLoading && <StyledZakekeViewer />}
			{showZoom && <ZoomInIcon onClick={zoomIn} />}
			{showZoom && <ZoomOutIcon onClick={zoomOut} />}
			{showFullscreen && <FullscreenIcon onClick={switchFullscreen} />}

			{product && product.isShowSecondScreenEnabled && (
				<SecondScreenIcon onClick={openSecondScreen} />
			)}

			{showAR &&
				isSceneArEnabled() &&
				(uiConfig && uiConfig.urlARIcon !== '' ? (
					<ArCustomIcon src={uiConfig.urlARIcon} alt='' onClick={() => handleArClick('ar.html')} />
				) : (
					<ArIcon onClick={() => handleArClick('ar.html')} />
				))}
			{dialogs.map((x) => React.cloneElement(x[1], { key: x[0] }))}
			{!longDelayedLoading && hasVTryOnEnabled && <TryOnsButton settings={getTryOnSettings()} />}
			{!isSceneLoading && !longDelayedLoading && hasVTryOnEnabled && <TryOnViewer />}
			{(isSceneLoading || isAssetsLoading) && (
				<>
					{uiConfig && uiConfig.urlLoader !== '' ? (
						<InitialLoaderContainer>
							<InitialLoader src={uiConfig.urlLoader} />
						</InitialLoaderContainer>
					) : (
						uiConfig && uiConfig?.urlLoader === '' && <ProgressBarLoadingOverlay flagStartLoading={flagStartLoading} />
					)}
				</>
			)}
			{isLoading && (
				<LoadingContainer>
					<TailSpin color='#000000' height={100} width={100} />
				</LoadingContainer>
			)}
		</Container>
	);
}

export default App;
