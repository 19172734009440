
import React from 'react';
import create from 'zustand';
import { TryOnMode, ZakekeTryOnExposedMethods } from '@zakeke/zakeke-configurator-react';

export const MOBILE_BREAKPOINT = 1024;

interface Store {
	isMobile: boolean;
	setIsMobile: (isMobile: boolean) => void;

	dialogs: { id: string; dialog: React.ReactElement }[];
	addDialog: (id: string, dialog: React.ReactElement) => void;
	removeDialog: (id: string) => void;

	tryOnRef?: React.RefObject<ZakekeTryOnExposedMethods>;
	setTryOnRef: (ref: React.RefObject<ZakekeTryOnExposedMethods>) => void;
	modeTryOn: TryOnMode;
	setTryOnMode: (modeTryOn: TryOnMode) => void;
}

const useStore = create<Store>((set) => ({
	isMobile: window.innerWidth <= MOBILE_BREAKPOINT,
	setIsMobile: (isMobile: boolean) => {
		set({
			isMobile
		});
	},

	dialogs: [],
	addDialog: (id: string, dialog: React.ReactElement) => {
		set((state) => ({
			dialogs: [...state.dialogs, { id, dialog }]
		}));
	},
	removeDialog: (id: string) => {
		set((state) => ({
			dialogs: state.dialogs.filter((dialog) => dialog.id !== id)
		}));
	},

	tryOnRef: undefined,
	setTryOnRef: (ref: React.RefObject<ZakekeTryOnExposedMethods>) => {
		set(() => ({
			tryOnRef: ref
		}));
	},

	modeTryOn: TryOnMode.TryOn,
	setTryOnMode: (modeTryOn: TryOnMode) => {
		set(() => ({
			modeTryOn
		}));
	},
}));

export default useStore;
